import React, { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import wheelAnimation_en from "../../assets/images/wheel-animation.gif";
import wheelImage_en from "../../assets/images/wheel-image.png";
import authService from "../../services/authServices";
import { toast } from "react-toastify";
function Home() {
  const navigate = useNavigate();
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const handleNavigate= ()=>{
    const id = searchParams.get("id");
    if(id)
    {
      localStorage.setItem('token',id)
      navigate(`/spin`)
    }
    else{
      toast.error("Can not fetch a valid user.")
    }
  }
  const handleAnimationLoaded = () => {
    setIsAnimationLoaded(true);
  };
  // clear local storage before proceeding.
  useEffect(() => {
    authService.clearUser();
  }, []);
  // adjust padding in button
  const findPaddingForButton = (text) => {
    let paddingDecided = "px-14 pt-4 pb-3";
    const wordsLength = text.split(" ").length;
    if (wordsLength <= 1) {
      return paddingDecided;
    }
    paddingDecided = "p-3";
    return paddingDecided;
  };
  return (
    <>
      <div>
        <p className="text-2xl p-2 font- text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center mt-2 ">
          CHOCOLATE PIZZA
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          WHEEL OF FORTUNE
        </p>
      </div>

      <div className="grid justify-center">
        <img
          className={`h-64 lg:h-96 ${isAnimationLoaded ? "block" : "hidden"}`}
          onLoad={handleAnimationLoaded}
          src={wheelAnimation_en}
          alt="wheel-II"
        />

        <img
          className={`mb-12 h-60 lg:h-[22rem]  ${
            isAnimationLoaded ? "hidden" : "block"
          } `}
          src={wheelImage_en}
          alt="wheel-I"
        />
      </div>
      <div className="flex justify-center">
        <button
          onClick={handleNavigate}
          className={`text-xl font-[futura-bold] ${findPaddingForButton(
            "ENTER"
          )} lg:text-3xl my-4  text-[#02154E] bg-slate-100  font-extrabold`}
        >
          SPIN
        </button>
      </div>
      <p className="text-base font-[futura] text-white my-5">
        *terms and conditions apply
      </p>
    </>
  );
}

export default Home;

import React, { useState} from "react";
import Roullete from "../spin/Roullete";
import CreateWin from "../win/CreateWin";
import i18next from "i18next";

function Registeration() {
  //states
  const currentLanguage = i18next.language;
  const [showPrize, setShowPrize] = useState(false);
  const [prizeDescription, setPrizeDescription] = useState({
    name: "",
    description: "",
  });
  return showPrize ? (
    <CreateWin prizeText={prizeDescription} />
  ) : (
    <Roullete
      setPrizeDescription={setPrizeDescription}
      setShowPrize={setShowPrize}
      currentLanguage={currentLanguage}
    />
  );
}

export default Registeration;

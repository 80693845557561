import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import spinnerStar from "../../assets/images/spin2.png";
import cursorIcon from "../../assets/images/coursorimg.png";
import { PrizeDataForSpinWheel } from "../helper/PrizeReference";
import pointerImage from "../../assets/images/pointer.png";
import { customInterceptors } from "../../services/config";
import { EndPoints } from "../../services/apiEndPoints";
import { toast } from "react-toastify";
import Border from "./Border";
import CryptoJS from "crypto-js";
import spinAudio from "../../assets/audio/wheel_rotate_sound.mp3";
import { encryptPlainText, formatCypherText } from "../helper/decode";
const API = customInterceptors();

function Roullete({ setPrizeDescription, setShowPrize}) {
  const sound = new Audio(spinAudio);
  //states
  const [mustSpin, setMustSpin] = useState(false);
  const [disableSpinning, setDisableSpinning] = useState(false);
  const [prizePayload, setPrizePayload] = useState({
    rewardId: "",
    rewardValue: "",
    sessionId: "",
    type:"",
    value:"",
  });

  // Function to handle spin button click
  const handleSpinClick = async () => {
    try {
      const encoded_url = localStorage.getItem("token");
      const sessionId = formatCypherText(encoded_url);
      if (!mustSpin && !disableSpinning && sessionId) {
        setDisableSpinning(true);
        const res = await API.post(EndPoints.CREATE_WIN, { encoded_url });
        // Check if the response status is one of the success statuses
        if (res?.data?.success) {
          //decrypt response
          const key = process.env.REACT_APP_REWARD_HASH_KEY;
          const iv = CryptoJS.enc.Hex.parse(
            process.env.REACT_APP_REWARD_IV_KEY
          );
          const encryptedText = CryptoJS.enc.Hex.parse(res.data.data);
          const decipher = CryptoJS.AES.decrypt(
            { ciphertext: encryptedText },
            CryptoJS.enc.Utf8.parse(key),
            {
              iv: iv,
            }
          );
          const decryptedPrize = CryptoJS.enc.Utf8.stringify(decipher);
          const prizeObject = JSON.parse(decryptedPrize);
          const prizeDescription = {
            name: prizeObject.name,
            description: prizeObject.description,
          };
          setPrizePayload({
            rewardId: prizeObject.id,
            rewardValue: prizeObject.value,
            type:prizeObject.type,
            value:prizeObject.product_value,
            sessionId,
          });
          setPrizeDescription(prizeDescription);
          setMustSpin(true);
          sound.play();
        } else {
          // Display an error message if some error occurred
          toast.error(res?.data?.message || "Some error occured");
          setDisableSpinning(false);
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        // Display an error message if there's an error in the response
        toast.error(err?.response?.data?.message);
        setDisableSpinning(false);
      }
    }
  };

  // Function to handle stopping the spinning animation
  const handleStopSpinning = async () => {
    setMustSpin(false);
    sound.pause();
    const enc_data = encryptPlainText(JSON.stringify(prizePayload));
    // Create form data
    const formData = new FormData();
    formData.append("success", "true");
    formData.append("data", enc_data);

    // Send the form data to the API
    try {
      const response = await fetch(
        "https://cp2024.thefamcomlab.com/wp-json/custom-api/v1/add-reward-data",
        {
          method: "POST",
          body: formData,
        }
      );

      // Parse the response as JSON
      const responseData = await response.json();

      // Log the response to the console
      if(responseData.success)
      {
        setTimeout(() => setShowPrize(true), 1000);
        localStorage.clear()
      }
      else{
        toast.error("Some error occured.")
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="text-2xl p-3 mt-5 px-8 text-white tracking-widest lg:tracking-[0.3em] flex justify-center lg:justify-end lg:-mb-10 place-items-center   ">
        CHOCOLATE PIZZA
      </div>
      <div className="overflow-hidden flex cursor-pointer">
        <div
          onClick={handleSpinClick}
          className="parent-container relative mt-2 bg-[#61171b]"
        >
          <div className="absolute h-[100%] w-[100%]">
            <div className="grid h-[100%] w-[100%]  justify-center place-content-center">
              <img
                height={110}
                width={110}
                style={{ zIndex: 200 }}
                src={spinnerStar}
                alt="spinnerStart-I"
              />
            </div>
          </div>
          <div className="absolute h-[100%] w-[100%]">
            <Border mustSpin={mustSpin} />
          </div>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizePayload.rewardValue}
            data={PrizeDataForSpinWheel}
            outerBorderColor={"#61171b"}
            radiusLineColor="none"
            // innerRadius={10}
            innerRadius={1}
            outerBorderWidth={3}
            spinDuration={0.8}
            pointerProps={{
              src: pointerImage,
              style: {
                rotate: "-40deg",
                transform: "translateX(-25px)",
              },
            }}
            onStopSpinning={handleStopSpinning}
          ></Wheel>
        </div>
      </div>
      <div className="flex justify-end w-[95%]">
        <div className="-mt-16 xl:-mt-32 2xl:-mt-52 mr-4 2xl:mr-16 p-3">
          <img
            className="h-auto my-5 mx-auto"
            src={cursorIcon}
            alt="pointer-I"
          />
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            TAP THE
          </p>
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            WHEEL TO SPIN
          </p>
        </div>
      </div>
    </>
  );
}

export default Roullete;

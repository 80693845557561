import CryptoJS from "crypto-js";
export const formatCypherText = (encryptedData) => {
    const encryptedDataFromPHP64 = CryptoJS.enc.Base64.parse(encryptedData);
    const key = process.env.REACT_APP_REWARD_HASH_KEY;
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedDataFromPHP64 },
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_REWARD_IV_KEY), // UTF8 encode the IV
        mode: CryptoJS.mode.CBC, // default
        padding: CryptoJS.pad.Pkcs7, // default              // Apply PKCS7 padding
      }
    );
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    let decryptedtext2 = CryptoJS.enc.Base64.parse(decryptedText).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedtext2

    // return JSON.parse(decryptedtext2);
  };

  export const encryptPlainText = (plainText) => {
    const key = process.env.REACT_APP_REWARD_HASH_KEY;
    const iv = process.env.REACT_APP_REWARD_IV_KEY;
  
    // Convert the plain text into a Base64 encoded string
    const base64PlainText = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plainText));
  
    // Encrypt the Base64 string using AES
    const encrypted = CryptoJS.AES.encrypt(
      base64PlainText,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
  
    // Convert the ciphertext into a Base64 encoded string
    const encryptedData = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    return encryptedData;
  };
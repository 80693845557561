import DIS10W from "../../assets/images/prize-images/10_per_w.png";
import DIS15W from "../../assets/images/prize-images/15_per_w.png";
import DIS5W from "../../assets/images/prize-images/5_per_w.png";
import DOL2W from "../../assets/images/prize-images/2_dol_w.png";
import DIS8B from "../../assets/images/prize-images/8_per_b.png";
import PRODCOCPIZ from "../../assets/images/prize-images/prod_coc_piz.png";
import PRODCOCCOO from "../../assets/images/prize-images/prod_coc_coo.png";
import PRODMILCOC from "../../assets/images/prize-images/prod_mil_coc.png";




export const PrizeDataForSpinWheel = [
  {
    option: "10% off coupon",
    image: { uri: DIS10W, offsetX: 2, offsetY: 100, sizeMultiplier: 1.5 },
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },

  },
  {
    option: "3 Assorted Mini Chocolate Pizzas",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri:PRODCOCPIZ, offsetX: 2, offsetY: 90, sizeMultiplier: 1.4 },
  },
  {
    option: "15% off coupon",
    style: { backgroundColor: "#61171b", textColor: "White" },
    image: { uri: DIS15W, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "5 Assorted Chocolate Cookies",
    style: { backgroundColor: "#CB0B2E", textColor: "White" },
    image: { uri: PRODCOCCOO, offsetX: 2, offsetY: 100, sizeMultiplier: 1.6 },
  },

  {
    option: "Free Milk Chocolate",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: PRODMILCOC, offsetX: 2, offsetY: 90, sizeMultiplier: 1.5 },
  },
  {
    option: "5% off coupon",
    style: { backgroundColor: "#61171b", textColor: "White" },
    image: { uri: DIS5W, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  }

];



export const arrangePrizeDescription = (prizeName, textMeta) => {
  const splitWords = prizeName.split(" ");
  if(textMeta==="find_dollar_sign")
  {
    if(splitWords[0].charAt(0)==="$")
    {
      return "$"
    }
      return null
  }
  if (textMeta === "prize_numeric_entity") {
    if(splitWords[0].charAt(0)==="$")
    {
      return splitWords[0].slice(1);
    }
    return splitWords[0]
  }
  if (textMeta === "prize_title1_entity") {
    return splitWords[1];
  }
  if (textMeta === "prize_title2_entity") {
    if (splitWords.length === 4) {
      return splitWords[2]+" "+splitWords[3];
    }
    return splitWords[2];
  }
};

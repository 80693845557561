import React from "react";
import mailicon from "../../assets/images/mailIcon.png";
import confettiMob from "../../assets/images/confetti.gif";

function CreateWin({ prizeText }) {
  let prizeDescription = prizeText.name;
  return prizeDescription ? (
    <>
      <div>
        <div className="absolute h-[100%] overflow-hidden opacity-40 -z-10">
          <img src={confettiMob} alt="confetti-I"></img>
        </div>
        {/* <p className="text-2xl p-2 text-white tracking-widest lg:tracking-[0.3em] flex justify-center mt-2 ">
          <img className="h-7" alt={"THlogo-I"} src={logo} />{" "}
        </p> */}
           <p className="text-2xl p-2 font- text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center mt-2 ">
          CHOCOLATE PIZZA
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          WHEEL OF FORTUNE
        </p>
        <p className="text-[3.35rem] leading-[1] tracking-widest mt-1 lg:text-[4.10rem] font-[futura] text-[#D61233] ">
          <span className="text-[#02154E]">*</span>
         GIVEAWAY
          <sup className="text-3xl lg:text-4xl">*</sup>
        </p>

      </div>
      <div className="px-5  ">
        <div className="flex justify-center">
          <hr className="border-t-2 w-[90%] lg:w-[60%] " />
        </div>
        <div className="grid text-center  py-2 place-content-center">
          <p className="text-3xl lg:text-4xl font-[futura-bold] tracking-wide    text-white">
            Congratulations!
          </p>
          <p className=" text-[#D61233] font-[futura-bold] text-2xl mt-4">
            You've Just Won A
          </p>
          <div className="grid grid-col-1 gap-x-2 place-content-center">
            <p className="text-5xl  font-[futura-bold] lg:text-6xl  text-white">
              {prizeText.name.toUpperCase()}
            </p>
            <div className=" font-[futura-bold] leading-7 text-xl lg:text-2xl text-white  place-content-start">
              <span>{prizeText.description.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <hr className="border-t-2 w-[90%] lg:w-[60%] " />
        </div>
      </div>
      <div className="flex gap-4 justify-center">
        <div className="grid place-content-center justify-center">
          <div className="rounded-[50%] h-8 w-8 bg-[#D61233] grid place-content-center">
            <img src={mailicon} width={18} alt="mail-I" />
          </div>
        </div>
        <div className="grid font-[futura] font-medium leading-5 place-content-center text-left text-lg lg:text-2xl  text-white">
          <p>PLEASE CHECK YOUR</p>
          <p>
            INBOX FOR YOUR<span className="text-[#D61233]">PRIZE!</span>
          </p>
        </div>
      </div>
      <p className="text-xs text-white my-5">
        {"*See "}
        <a href={"/"}>
          <span className="underline">Terms and Conditions</span>
        </a>
        {" for details."}
      </p>
    </>
  ) : (
    ""
  );
}

export default CreateWin;
